<template>
  <div className="mx-auto">
    <div className="mt-12">
      <div class="mb-10 lg:max-w-2xl md:mb-12">
        <h2
          class="mb-6 text-5xl font-bold leading-none tracking-tight  text-purple"
        >
          Contact Us
        </h2>

        <p class="text-base text-gray-700 md:text-lg">
          <p>
          Want to discuss any custom requirements for Triton? Are you facing an
          issue? We are here to help! 
          </p> 
          <p>
          Fill the form
          <span
            ><a
              href="https://forms.monday.com/forms/embed/38cf903b098497847d2023fa6e9400ed?r=use1"
              rel="noopener noreferrer"
              target="_blank"
              className="text-purple font-semibold"
              >here
            </a></span
          >, mail us at
          <span
            ><a
              href="mailto:support@triton.one"
              className="text-purple font-semibold"
              >support@triton.one</a
            ></span
          >
          or get in touch with us over 
          <a href="https://t.me/joinchat/K0ONdq7fE4s0Mjdl" className="text-purple font-semibold">Telegram</a> or
          via 
          <a href="https://discord.gg/rbgvBum63B" className="text-purple font-semibold">Discord</a>.
          </p>
        </p>
      </div>
      <div class="max-w-5xl mx-auto text-center">
        <!-- <iframe
          src="https://forms.monday.com/forms/embed/38cf903b098497847d2023fa6e9400ed?r=use1"
          width="800"
          height="1100"
          style="border: 0;  rgba(0, 0, 0, 0.25)"
        ></iframe> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Button from "./../components/Button.vue";
import TextField from "./../components/TextField.vue";
import Partners from "./../components/Partners.vue";
import { useHead } from "@vueuse/head";
export default {
  setup() {
    useHead({
      title: "Contact: Triton",
      meta: [
        {
          name: "description",
          content: "Triton contact us page",
        },
      ],
    });
  },
  name: "contact",
  title: "contact",

  components: {
    Button,
    TextField,
    Partners,
  },
};
</script>

<!-- <section
        class="p-6 bg-white border-2 border-gray-200 rounded-xl dark:bg-gray-800 lg:max-w-2xl"
      >
        <form name="contact" method="POST" data-netlify="true">
          <div class="flex flex-col">
            <div class="mb-6">
              <label class="text-gray-700 dark:text-gray-200" for="username"
                >Name</label
              >
              <input
                id="username"
                type="text"
                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-xl dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none"
              />
            </div>

            <div class="mb-6">
              <label class="text-gray-700 dark:text-gray-200" for="emailAddress"
                >Email</label
              >
              <input
                id="emailAddress"
                type="email"
                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-xl dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none"
              />
            </div>
            <div>
              <label class="text-gray-700 dark:text-gray-200" for="emailAddress"
                >message</label
              >
              <textarea
                id="emailAddress"
                type="text"
                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-xl dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none"
              ></textarea>
            </div>
          </div>

          <div class="flex mt-6 justify-self-stretch">
            <button
              class="p-2 px-4 mt-1 font-medium text-center text-white transition-colors duration-300 bg-purple rounded-xl"
            >
              Save
            </button>
          </div>
        </form>
      </section> -->
