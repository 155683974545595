<template>
  <input
    className="px-3 py-2  w-full font-semibold tracking-tight placeholder-placeholder-purple rounded-xl bg-white mb-4"
    :placeholder="text"
    :name="name"
    :type="type"
    required
  />
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    name: String,
    type: String,
  },
};
</script>

<style scoped></style>
